import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
// React Slick
import Slider from "react-slick"
// Components

const SlideMain = () => {
  const dataSlides = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)|(webp)/" }
          relativeDirectory: { eq: "slide-image" }
        }
      ) {
        nodes {
          childImageSharp {
            gatsbyImageData(
              width: 1800
              quality: 100
              webpOptions: { quality: 100 }
              formats: [WEBP, AUTO]
            )
          }
        }
      }
    }
  `)
  const slides = dataSlides.allFile.nodes
  const settings = {
    dots: true,
    infinite: true,
    speed: 3500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
  }
  return (
    <Slider {...settings}>
      {slides.map((slide, i) => (
        <div key={i}>
          <GatsbyImage
            image={getImage(slide?.childImageSharp?.gatsbyImageData)}
            alt=""
          />
        </div>
      ))}
    </Slider>
  )
}

export default SlideMain
